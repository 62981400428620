import getDataFromStorage from '@/helpers/getDataFromStorage'

export default async function (context, middleware = []) {
  const { tokenCurrent } = getDataFromStorage()

  const checkLoggedIn = Boolean(tokenCurrent)

  // klo middleware nya ada, maka wajib pengechekan login atau role akses page lainnya wajib di check di dalam scope if ini.
  if (middleware.length) {
    if (checkLoggedIn) {
      return context.next()
    }
  }

  return context.next('/login')
}
