import resource from '../resource'

const endpoints = {
  universitas: '/user/master/universitas',
  fakultas: '/user/master/fakultas',
  jurusan: '/user/master/jurusan',
  pekerjaan: '/user/master/bidang-pekerjaan',
  count: 'evote/election/count',
}

export default {
  getUniv: (qs = '') => {
    const url = `${endpoints.universitas}${qs ? `?${qs}` : ''}`
    const request = resource.get(url)
    return request
  },
  getFakultas: (qs = '') => {
    const url = `${endpoints.fakultas}${qs ? `?${qs}` : ''}`
    const request = resource.get(url)
    return request
  },
  getJurusan: (qs = '') => {
    const url = `${endpoints.jurusan}${qs ? `?${qs}` : ''}`
    const request = resource.get(url)
    return request
  },
  getPekerjaan: (qs = '') => {
    const url = `${endpoints.pekerjaan}${qs ? `?${qs}` : ''}`
    const request = resource.get(url)
    return request
  },
  getCountAllVote: () => {
    const request = resource.post(`${endpoints.count}`)
    return request
  },
}
