import resource from '../resource'

const endpoints = {
  login: '/evote/login',
  checkToken: '/user/auth/check',
  logout: '/evote/logout',
}

export default {
  login: (data = { email: '', password: '' }) => {
    const request = resource.post(`${endpoints.login}`, data)
    return request
  },
  checkToken: () => {
    const request = resource.post(`${endpoints.checkToken}`)
    return request
  },
  logout: () => {
    const request = resource.post(`${endpoints.logout}`)
    return request
  },
}
