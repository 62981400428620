import auth from './modules/auth'
import election from './modules/election'
import master from './modules/master'
import candidate from './modules/candidate'
import campaign from './modules/campaign'

export default {
  auth,
  election,
  master,
  candidate,
  campaign,
}
