import {
  TOKEN_CURRENT,
  USER_DATA,
} from '@/constants/index'
import storage from './storage'

const getUserDataFromStorage = () => ({
  tokenCurrent: storage.getStorage(TOKEN_CURRENT),
  userData: storage.getStorage(USER_DATA)
    ? storage
      .getStorage(USER_DATA).split('_')
      .map(item => item.split('-'))
      .reduce((prev, current) => {
        const obj = { ...prev, [current[0]]: current[1] }
        return obj
      }, {})
    : undefined,
})

export default getUserDataFromStorage
