import resource from '../resource'

const endpoints = {
  candidate: '/evote/candidates',
  updateCandidate: '/evote/candidates/update',
}

export default {
  /**
   * getCandidate
   * @param {string} qs example: election_id=12
   */
  getCandidate: (qs = '') => {
    const url = `${endpoints.candidate}${qs ? `?${qs}` : ''}`
    const request = resource.get(url)
    return request
  },
  /**
   *
   * createCandidate
   * @param {Object} data
   * example data {
   *  "email": "calon@calon.com",
   *  "visi": "mencari cinta",
   *  "misi": "menemukan cinta",
   *  "no_urut": "1",
   *  "election_id": "3",
   *  "member_id": "15
   * }
   *
  */
  createCandidate: data => {
    console.log('data', data)
    const url = `${endpoints.candidate}`
    const request = resource.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return request
  },
  updateCandidate: (data = {
    id: Number(),
    email: '',
    visi: '',
    misi: '',
    no_urut: '',
    election_id: '',
    member_id: '',
  }) => {
    const url = `${endpoints.updateCandidate}`
    const request = resource.post(url, data)
    return request
  },
  deleteCandidate: (id = Number) => {
    const url = `${endpoints.candidate}/${id}`
    const request = resource.delete(url)
    return request
  },
}
