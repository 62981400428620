import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import { BModal, BFormGroup, BFormInput } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('b-modal', BModal)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-input', BFormInput)
