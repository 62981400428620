import resource from '../resource'

const endpoints = {
  electionAdmin: 'evote/admin/result',
  election: '/evote/election',
  updateElection: '/evote/election/update',
  totalVotesByFaculties: '/evote/election/count/faculty ',
}

const uniqueArray = arr => [...new Set(arr)]

export default {
  /**
   * getElection
   * @param {string} qs example: start_at=2018-08-08 00:00:00&finish_at=2018-08-08 00:00:00&searh=iluni
   */
  getElection: (qs = '') => {
    const url = `${endpoints.election}${qs ? `?${qs}` : ''}`
    const request = resource.get(url)
    return request
  },
  createElection: (data = {
    name: '', start_at: new Date(), finish_at: new Date(), faculties: [],
  }) => {
    const url = `${endpoints.election}`
    const request = resource.post(url, {
      ...data,
      // hardcode id faculty 6
      faculties: uniqueArray([...data.faculties]),
    })
    return request
  },
  updateElection: (data = {
    name: '', start_at: new Date(), finish_at: new Date(), faculties: [],
  }) => {
    const url = `${endpoints.updateElection}`
    const request = resource.post(url, {
      ...data,
      // hardcode id faculty 6
      faculties: uniqueArray([...data.faculties]),
    })
    return request
  },
  deleteElection: (id = Number) => {
    const url = `${endpoints.election}/${id}`
    const request = resource.delete(url)
    return request
  },
  resultVote: () => {
    const request = resource.post(`${endpoints.electionAdmin}`)
    return request
  },
  resulVoteByFaculties: () => {
    const request = resource.get(`${endpoints.totalVotesByFaculties}`)
    return request
  },
}
