import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

Vue.mixin({
  methods: {
    $_customConfirm({ title, text, icon = 'warning' }) {
      return new Promise(resolve => {
        (async () => {
          const options = {
            icon,
            showCancelButton: true,
            confirmButtonText: 'Iya',
            cancelButtonText: 'Tidak',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }
          if (title) options.title = title
          if (text) options.text = text

          const { value } = await this.$swal(options)
          resolve(value)
        })()
      })
    },
    $_checkValidateOfForm(ref) {
      return new Promise((resolve, reject) => (async () => {
        const validated = await this.$refs[ref].validate()
        if (!validated) {
          this.$_handleMsgShuldCompleteFillInputs()
          return reject()
        }
        return resolve(true)
      })())
    },
    $_handleMsgShuldCompleteFillInputs(msg = 'Silahkan lengkapi input yang harus diisi terlebih dahulu.') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: 'BellIcon',
          variant: 'danger',
          setTimeout: '5000',
        },
      })
    },
    $_handleMsgSuccess(title = 'Berhasil menyimpan') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    $_handleMsgErrors(error, title = 'Gagal menyimpan') {
      if (error.response.status === 422) {
        if (error.response.data.errors.length) {
          const self = this
          error.response.data.errors.forEach((err, i) => {
            setTimeout(() => {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: err.message,
                  icon: 'BellIcon',
                  variant: 'danger',
                  setTimeout: '5000',
                },
              })
            }, 1000 * i)
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'BellIcon',
              variant: 'danger',
              setTimeout: '3000',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon: 'BellIcon',
            variant: 'danger',
            setTimeout: '3000',
          },
        })
      }
    },
  },
})
