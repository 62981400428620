export default [
  {
    path: '/admin-page',
    name: 'admin',
    component: () => import('@/views/dummy/SecondPage.vue'),
    meta: {
      pageTitle: 'Admin page',
      breadcrumb: [
        {
          text: 'Admin page',
          active: true,
        },
      ],
      // agar page hanya bisa di akses oleh user yang memiliki akses pada halaman Admin.
      middleware: 'Auth',
    },
  },
]
