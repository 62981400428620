export default [
  {
    path: '/elections',
    name: 'elections',
    component: () => import('@/views/election/Election.vue'),
    meta: {
      pageTitle: 'Election',
      breadcrumb: [
        {
          text: 'Election',
          active: true,
        },
      ],
      middleware: 'Auth',
    },
  },
  {
    path: '/candidates',
    name: 'candidates',
    component: () => import('@/views/candidate/Candidate.vue'),
    meta: {
      pageTitle: 'Candidate',
      breadcrumb: [
        {
          text: 'Candidate',
          active: true,
        },
      ],
      middleware: 'Auth',
    },
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    component: () => import('@/views/campaign/Campaign.vue'),
    meta: {
      pageTitle: 'Campaign',
      breadcrumb: [
        {
          text: 'Campaign',
          active: true,
        },
      ],
      middleware: 'Auth',
    },
  },
  {
    path: '/vote/recapulations',
    name: 'recapulation',
    component: () => import('@/views/recapulation/Recapulation.vue'),
    meta: {
      layout: 'full',
      footer: false,
      middleware: 'Auth',
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/Home.vue'),
    meta: {
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
      middleware: 'Auth',
    },
  },
  {
    path: '/total-votes',
    name: 'total-votes',
    component: () => import('@/views/totalVote/totalVote.vue'),
    meta: {
      pageTitle: 'Total Votes',
      breadcrumb: [
        {
          text: 'Total Votes',
          active: true,
        },
      ],
      middleware: 'Auth',
    },
  },
  {
    path: '/access-denied',
    name: 'access-denied',
    component: () => import('@/views/accessDenied/AccessDenied.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    // src/views/VoteCounter/VoteCounter.vue
    path: '/total-votes/by/faculty',
    name: 'total-votes-by-faculty',
    component: () => import('@/views/VoteCounter/VoteCounter.vue'),
    meta: {
      pageTitle: 'Total Votes',
      breadcrumb: [
        {
          text: 'By Faculty',
          active: true,
        },
      ],
      middleware: 'Auth',
    },
  },
]
