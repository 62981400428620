import resource from '../resource'

const endpoints = {
  campaign: '/evote/campaign',
  updateCampaign: '/evote/campaign/update',
}

export default {
  getCampaign: (qs = '') => {
    const url = `${endpoints.campaign}${qs ? `?${qs}` : ''}`
    const request = resource.get(url)
    return request
  },
  createCampaign: (data = { }) => {
    const url = `${endpoints.campaign}`
    const request = resource.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return request
  },
  updateCampaign: (data = {}) => {
    const url = `${endpoints.updateCampaign}`
    const request = resource.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return request
  },
  deleteCampaign: (id = Number) => {
    const url = `${endpoints.campaign}/${id}`
    const request = resource.delete(url)
    return request
  },
}
